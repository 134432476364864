.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #09d3ac;
}

#root {
    height: 100%;
}

.ant-page-header-heading-sub-title {
    display: flex !important;
    align-items: center !important;
}

.ant-page-header-back {
    margin-right: 0 !important;
}

.ant-page-header-back-button {
    padding: 10px 15px !important;
}

.ant-page-header-content {
    padding-top: 24px !important;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
    font-weight: 900 !important;
}
